import * as React from "react"
import { useState, useEffect } from "react"
import axios from "axios"
import { STRIPE_SERVERLESS_API_BASE } from "../../constants"
import { useRecoilValue } from "recoil"
import { userState } from "../../store"
import { timeStampToDate, sizeBg } from "../../utils"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"

export function BillingHistory() {
  const theme = useTheme()
  const [history, setHistory] = useState(null)
  const user = useRecoilValue(userState)

  useEffect(() => {
    async function fetchData() {
      const data = await axios
        .get(STRIPE_SERVERLESS_API_BASE + "/invoices/list", {
          params: {
            customer: user.customer,
          },
        })
        .catch(error => {
          console.error("error - /invoices/list", error)
          // throw Error(error.response.data.message)
        })

      setHistory(data)
    }
    user.customer && fetchData()
  }, [])
  const isLoading = history === null
  return (
    <>
      <System.LabelLarge>BILLING HISTORY</System.LabelLarge>
      <System.Spacing height="scale700" backgroundColor={sizeBg} />
      {isLoading && (
        <System.Block
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <System.Spinner />
        </System.Block>
      )}
      {!isLoading &&
        history.data.data.map(
          (item, id) =>
            item.hosted_invoice_url && (
              <System.ListItem
                key={id}
                endEnhancer={
                  <a href={item.hosted_invoice_url} target="_blank">
                    <System.Icons.ExternalLink
                      width={theme.sizing.scale600}
                      color={theme.colors.contentPrimary}
                    />
                  </a>
                }
              >
                <System.ListItemLabel>
                  <System.LabelMedium>
                    {timeStampToDate(item.date | item.created)}
                  </System.LabelMedium>
                  <System.ParagraphXSmall>
                    {item.amount_paid / 100} $
                  </System.ParagraphXSmall>
                </System.ListItemLabel>
              </System.ListItem>
            )
        )}
    </>
  )
}

BillingHistory.defaultProps = {}
